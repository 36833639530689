.card-container {
  background-color: white;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 50vh;
  width: 20vw;
  margin: 0rem 2rem;
  padding: 0.75rem;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.card-image {
  border-radius: 5px;
  margin-bottom: 0.75rem;
}

.card-image img {
  width: 100%;
  max-width: 18rem;
  height: auto;
  max-height: 10rem;
  object-fit: contain;
}

.card-heading {
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
}

.card-content {
  font-size: 0.75rem;
  text-align: justify;
  margin-bottom: 0.75rem;
}

.read-more {
  background-color: #c0deff;
  border-radius: 0.625rem;
  padding: 0.3rem 2rem;
  margin: 0.75rem;
}

@media (max-width: 1024px) {
  .card-container {
    width: 30vw;
    margin: 0rem 1.5rem;
  }
}

@media (max-width: 768px) {
  .card-container {
    width: 35vw;
    margin: 0rem 1rem;
  }

  .card-heading {
    font-size: 0.75rem;
  }

  .card-content {
    font-size: 0.5rem;
  }

  .read-more {
    padding: 0.3rem 1rem;
    margin: 0.5rem 0.5rem 0rem;
  }
}

@media (max-width: 576px) {
  .card-container {
    height: 32vh;
    width: 50vw;
    margin: 0rem 1.5rem;
  }

  .card-image img {
    max-width: 14rem;
    max-height: 6rem;
  }
}
