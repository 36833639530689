.contact-container {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 0.625rem;
  margin: 8rem 8rem 2rem 8rem;
  padding: 4rem 8rem;
  text-align: center;
}

.left {
  width: 50%;
  margin: 0rem 2rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right {
  width: 50%;
  margin: 0rem 2rem;
  padding: 1rem 2rem;
  background-color: #e3dffd;
  border-radius: 0.625rem;
}

.heading {
  /* font-family: "Montserrat", sans-serif; */
  font-weight: 700;
  font-size: 2rem;
  color: #000000;
  margin-bottom: 1rem;
}

.content {
  /* font-family: "Montserrat", sans-serif; */
  font-weight: 400;
  font-size: 1rem;
  color: #000000;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.box {
  width: 100%;
  border-radius: 0.625rem;
  margin: 1rem 0;
  padding: 0.5rem;
  resize: none;
}

.send {
  background-color: #fff8e1;
  font-size: 1rem;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    margin: 4rem 2rem;
    padding: 2rem;
    text-align: center;
  }

  .left,
  .right {
    width: 100%;
    margin: 0;
    padding: 1rem;
  }

  .right {
    margin-top: 2rem;
  }

  .heading {
    font-size: 1.5rem;
  }

  .content {
    font-size: 0.9rem;
  }

  .box {
    margin: 0.5rem 0;
  }
}
