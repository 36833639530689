.blog-container {
  /* margin: 6rem; */
  position: relative;
}

.blog-heading-container {
  padding: 6rem 0rem 6rem 6rem;
  background-color: #aea3ff;
  display: flex;
  color: white;
  justify-content: space-between;
}

.blog-title {
  font-size: 3.5rem;
  font-weight: bold;
  margin: 1rem;
}

.blog-subtitle {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 1rem;
  padding-right: 12rem;
}

.cross {
  position: absolute;
  left: 1rem;
}

.blog-img {
  position: relative;
  right: 1rem;
  width: 100%;
  max-width: 18rem;
  height: auto;
  max-height: 14rem;
  object-fit: contain;
  align-self: center;
}

.blog-body-container {
  margin: 4rem;
  display: flex;
}

.blog-recents-container {
  margin: 1rem 0rem;
  width: 20%;
  display: flex;
  justify-content: space-between;
}

.blog-recents-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.blog-recents-heading {
  font-size: 1.25rem;
  font-weight: bold;
}

.vertical-line {
  border-left: 1px solid black;
  margin: 0rem 2rem 0rem 1rem;
}

.blog-body {
  flex: 1;
  max-width: 100%;
  overflow-wrap: break-word;
}

.blog-subheading {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 1rem auto;
}

.blog-content {
  text-align: justify;
}

@media only screen and (max-width: 768px) {
  .blog-heading-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 8rem 2rem 2rem;
  }

  .cross {
    left: 0.5rem;
    top: 5rem;
    width: 1.5rem;
  }

  .blog-img {
    position: initial;
  }

  .blog-title {
    font-size: 2rem;
    margin: 1rem 0rem;
  }

  .blog-subtitle {
    font-size: 1.25rem;
    margin: 1rem 0rem;
    padding-right: 0rem;
  }

  .blog-body-container {
    flex-direction: column-reverse;
    margin: 2rem;
  }

  .blog-recents-container {
    width: 100%;
    text-align: center;
    margin-top: 4rem;
  }

  .vertical-line {
    display: none;
  }
}
