nav {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
  margin: 0rem auto;
  color: white;
  /* -moz-transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in; */
}

.nav-container {
  z-index: 5;
  display: flex;
  justify-content: space-evenly;
  margin: 1.625rem 16rem;
}

.spacer {
  display: none;
  width: 0%;
}

.nav-logo-container {
  margin-top: -1rem;
  margin-bottom: -1rem;
  width: 10vw;
}

.videos,
.blogs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.videos:hover,
.blogs:hover {
  /* cursor: pointer; */
  /* background-color: #9886be; */
}

.dropdown {
  display: none;
  position: fixed;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #9886be;
  opacity: 0.75;
  border: 1px solid #9b91ba;
  color: white;
  z-index: 10;
  margin-top: 1.5rem;
  padding: 0.2rem 0rem;
  border-radius: 0.625rem;
  font-size: 0.75rem;
  gap: 0.5rem;
  min-width: 8rem;
  /* transform: translate(-3rem, 0rem); */
}

.dropdow-mob {
  display: block;
  position: fixed;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #9886be;
  opacity: 0.75;
  border: 1px solid #9b91ba;
  color: white;
  z-index: 10;
  margin-top: 1.5rem;
  padding: 0.2rem 0rem;
  border-radius: 0.625rem;
  font-size: 0.75rem;
  gap: 0.5rem;
  min-width: 8rem;
}

.blogs:hover .dropdown {
  display: flex;
}

.videos:hover .dropdown {
  display: flex;
}

.hamburger {
  display: none;
}

.hamburger-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #9886be;
  opacity: 0.95;
  border: 1px solid #9b91ba;
  /* color: white; */
}

@media (max-width: 1024px) {
  .nav-container {
    display: none;
  }

  .nav-container {
    justify-content: space-between;
  }

  .hamburger {
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 1rem;
    z-index: 10;
  }

  .nav-logo-container {
    margin: 1rem 2rem;
    width: 25vw;
  }
}
