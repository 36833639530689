.about-content {
  margin: 0rem auto;
  display: flex;
  flex-direction: column;
  line-height: 2rem;
  padding: 4rem;
}

.parallax {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.parallax::-webkit-scrollbar {
  display: none;
}

.upper-content {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 2rem;
}

.lower-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem;
  gap: 2rem;
}

.para {
  flex: 1;
}

.about-img {
  z-index: 10;
}

.about-img img {
  z-index: 10;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  .about-content {
    padding: 1rem;
  }
}
