.box-container {
  background-color: white;
  border-radius: 0.625rem;
  margin: 6rem;
  padding: 3rem;
}

.left-right {
  display: flex;
  flex-direction: row;
}

.right-left {
  display: flex;
  flex-direction: row-reverse;
}

.box-image-side {
  width: 60%;
  display: flex;
  align-items: center;
}

.right-left .box-image-side {
  justify-content: flex-end;
}

.box-text-side {
  width: 40%;
  margin: 0rem 3rem;
}

.box-image {
  border-radius: 5px;
  margin-bottom: 0.75rem;
  width: 100%;
  max-width: 20rem;
  height: auto;
  max-height: 14rem;
  object-fit: contain;
}

.box-heading {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
}

.box-line {
  border: 1px solid #000000;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.box-content {
  font-size: 1rem;
  text-align: justify;
  margin: 2rem;
}

.read-full {
  display: flex;
  justify-content: center;
  background-color: #c0deff;
  border-radius: 0.625rem;
  width: 90%;
  font-size: 1.125rem;
  padding: 0.5rem 1.5rem;
  margin: 2rem;
}

.read-full > div:first-child {
  margin-right: auto;
  visibility: hidden;
}

.read-full .arrows {
  margin-left: auto;
}

@media only screen and (max-width: 768px) {
  .box-container {
    margin: 3rem;
    padding: 1.5rem;
    flex-direction: column;
    align-items: center;
  }

  .left-right,
  .right-left {
    flex-direction: column;
  }

  .right-left .box-image-side {
    justify-content: center;
  }

  .box-image-side {
    width: 100%;
    justify-content: center;
  }

  .box-text-side {
    width: 100%;
    margin: 0rem;
  }

  .box-heading {
    font-size: 1.25rem;
  }

  .box-content {
    margin: 1rem 0rem;
    font-size: 0.75rem;
  }

  .read-full {
    width: 100%;
    margin: 1.5rem 0rem 0rem 0rem;
  }

  .box-image {
    max-width: 16rem;
    max-height: 10rem;
  }
}
