.bottom-grad {
  height: 200px;
  background-image: linear-gradient(#e3dffd, #ada2ff);
  z-index: 1;
}

.bottom-container {
  background-color: #ada2ff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-logo {
  position: relative;
}

.footer-logo-image {
  position: absolute;
  top: -2.4rem;
  left: 3.7rem;
}

.footer-logo-circle {
  position: relative;
  top: -6rem;
  left: 0;
}

.subscribe-container {
  padding: 2rem 14rem 4rem 14rem;
  display: flex;
  justify-content: space-evenly;
}

.subscribe-left {
  align-self: center;
  width: 50%;
  margin: 2rem;
  padding: 2rem;
}

.subscribe-heading {
  font-weight: 500;
  font-size: 1.25rem;
  color: #000000;
  margin-bottom: 1rem;
  text-align: justify;
}

.subscribe-content {
  font-weight: 400;
  font-size: 0.75rem;
  color: #000000;
  text-align: center;
}

.line {
  border: 1px solid #000000;
  margin: 0rem 2rem;
}

.subscribe-right {
  width: 50%;
  margin: 2rem;
  padding: 2rem;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 100%;
  border-radius: 0.625rem;
  margin: 1rem 0;
  padding: 0.5rem;
  resize: none;
}

.submit {
  background-color: #fff8e1;
  font-size: 1rem;
  font-weight: bold;
}

.footer-socials-container {
  margin: 1rem;
}

.follow {
  font-size: 1.25rem;
}

.footer-socials {
  display: flex;
  justify-content: space-evenly;
}

.footer-note {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  width: 40%;
}

.left-note {
  width: 50%;
  text-align: center;
  align-self: center;
}

.right-note {
  width: 50%;
  text-align: center;
  align-self: center;
}

@media only screen and (max-width: 768px) {
  .bottom-container {
    padding: 0rem 1rem;
    text-align: center;
  }

  .footer-logo {
    text-align: center;
    margin-bottom: 2rem;
  }

  .subscribe-container {
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
  }

  .subscribe-left,
  .subscribe-right {
    width: 100%;
    padding: 1rem;
  }

  .subscribe-right {
    margin-top: 1rem;
  }

  .subscribe-heading {
    font-size: 1.25rem;
  }

  .footer-note {
    width: 100%;
    padding: 1rem;
    font-size: 0.75rem;
  }

  .subscribe-container .line {
    width: 90%;
  }
}
