.skeleton-container {
  background-color: #f2f2f2;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 50vh;
  width: 20vw;
  margin: 0rem 2rem;
  padding: 0.75rem;
}

.skeleton-image {
  width: 100%;
  height: 12rem;
  background-color: #d9d9d9;
  border-radius: 5px;
  animation: fadeIn 1s ease-in-out infinite;
}

.skeleton-heading {
  width: 100%;
  height: 2rem;
  background-color: #d9d9d9;
  margin-top: 0.5rem;
  animation: fadeIn 1s ease-in-out infinite;
}

.skeleton-content {
  width: 100%;
  height: 3rem;
  background-color: #d9d9d9;
  margin-top: 0.75rem;
  animation: fadeIn 1s ease-in-out infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 0.25;
  }
}

@media (max-width: 1024px) {
  .skeleton-container {
    width: 30vw;
    margin: 0rem 1.5rem;
  }
}

@media (max-width: 768px) {
  .skeleton-container {
    width: 35vw;
    margin: 0rem 1rem;
  }

  .skeleton-heading {
    font-size: 0.75rem;
  }

  .skeleton-content {
    font-size: 0.5rem;
  }
}

@media (max-width: 576px) {
  .skeleton-container {
    height: 32vh;
    width: 50vw;
    margin: 0rem 1.5rem;
  }
}
