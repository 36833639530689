.unsubscribe-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  text-align: center;
}

.unsubscribe-heading {
  font-size: 150px;
  color: #b22222;
  text-shadow: 1px 1px 1px #8b0000, 2px 2px 1px #8b0000, 3px 3px 1px #8b0000,
    4px 4px 1px #8b0000, 5px 5px 1px #8b0000, 6px 6px 1px #8b0000,
    7px 7px 1px #8b0000, 8px 8px 1px #8b0000, 25px 25px 8px rgba(0, 0, 0, 0.2);
}

.unsubscribe-content {
  margin: 2rem 0;
  font-size: 20px;
  font-weight: 600;
  color: #444;
}

.unsubscribe-link {
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 9rem;
  background-color: #fff8e1;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.5rem 1rem;
  transition: all 0.2s linear;
  box-shadow: 0 10px 10px -11px rgba(0, 0, 0, 0.4);
  border-radius: 0.625rem;
}

.unsubscribe-link:hover {
  background-color: #f1e1ad;
}
