@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

@font-face {
  font-family: "Rugen";
  src: local("Rugen"), url(./assets/fonts/RugenExpanded-DOKGE.otf);
}

body,
html {
  font-family: "Open Sans", sans-serif;
  scroll-behavior: smooth;
  background-color: #e3dffd;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}
