.featured-heading {
  font-family: "Rugen";
  font-size: 2rem;
  text-align: center;
  margin: 2rem;
}

.chevron {
  cursor: pointer;
  transition: 0.1s ease-in;
}

#right:hover {
  transform: translateX(5%) scale(1.05);
}

#left:hover {
  transform: rotate(180deg) translateX(5%) scale(1.05);
}

.inverted {
  transform: rotate(180deg);
}

.view-all {
  display: flex;
  margin: 2rem;
  margin-left: auto;
  max-width: fit-content;
}

.chevrons-right {
  transition: 0.2s ease-in;
}

.view-all:hover .chevrons-right {
  /* transform: scale(1.2); */
  transform: translateX(20%) scale(1.1);
}

.featured-container {
  display: flex;
}

.cards-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  overflow: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.cards-container::-webkit-scrollbar {
  display: none;
}

@media (max-width: 768px) {
  .featured-heading {
    font-size: 1.75rem;
  }

  .view-all {
    margin: 1rem;
    margin-left: auto;
  }
}
