.comingsoon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  height: 40vh;
}

.comingsoon-heading {
  /* font-family: "Rugen"; */
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  margin: 2rem;
}

.comingsoon-container {
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 2rem;
}

@media only screen and (max-width: 768px) {
  .comingsoon-heading {
    font-size: 2rem;
  }

  .comingsoon-container {
    grid-template-columns: 1fr 1fr;
    margin: 1rem;
  }
}
