.rcard-container {
  background-color: white;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 18vw;
  height: 36vh;
  margin: 2rem;
  padding: 2rem;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  transition: 0.2s;
  box-shadow: 0 0 0.625rem 0.5rem rgba(0, 0, 0, 0.05);
}

.rcard-container:hover {
  /* box-shadow: 0 0 0.625rem 0.625rem rgba(0, 0, 0, 0.1); */
  transform: scale(1.1);
}

.rcard-image {
  border-radius: 0.625rem;
  margin-bottom: 0.75rem;
}

.rcard-content {
  font-size: 1rem;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .rcard-container {
    width: 38vw;
    height: 28vh;
    padding: 1rem;
  }

  .rcard-content {
    font-size: 0.9rem;
  }
}
