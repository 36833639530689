.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  text-align: center;
}

.not-found-heading {
  font-size: 150px;
  color: #b22222;
  text-shadow: 1px 1px 1px #8b0000, 2px 2px 1px #8b0000, 3px 3px 1px #8b0000,
    4px 4px 1px #8b0000, 5px 5px 1px #8b0000, 6px 6px 1px #8b0000,
    7px 7px 1px #8b0000, 8px 8px 1px #8b0000, 25px 25px 8px rgba(0, 0, 0, 0.2);
}

.not-found-content {
  margin: 2rem 0;
  font-size: 20px;
  font-weight: 600;
  color: #444;
}

.not-found-link {
  display: inline-block;
  border: 2px solid #222;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.75rem 1rem 0.6rem;
  transition: all 0.2s linear;
  box-shadow: 0 15px 15px -11px rgba(0, 0, 0, 0.4);
  background: #222;
  border-radius: 6px;
}

.not-found-link:hover {
  background: #222;
  color: #ddd;
}
