.content-container {
  margin: 4rem;
}

.content-heading {
  font-size: 3.5rem;
  font-weight: bold;
  text-align: center;
  margin: 1rem;
}

@media only screen and (max-width: 768px) {
  .content-container {
    margin: 0rem;
  }

  .content-heading {
    font-size: 2.5rem;
  }
}
