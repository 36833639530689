.no-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  text-align: center;
}

.no-content-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  padding: 3rem;
  margin: 2rem;
  height: 25rem;
  width: 25rem;
}

.no-content-heading {
  font-size: 2.5rem;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .no-content-image-wrapper {
    padding: 2rem;
    height: 20rem;
    width: 20rem;
  }

  .no-content-heading {
    font-size: 1.75rem;
  }
}
