.recent-box-container {
  display: flex;
  margin: 1rem 0rem;
  gap: 1rem;
}

.recent-box-container:hover {
  cursor: pointer;
}

.recent-box-text-side {
  width: 60%;
}

.recent-box-image-side {
  display: flex;
  align-items: center;
}

.recent-box-image {
  border-radius: 5px;
  margin-bottom: 0.75rem;
  width: 100%;
  max-width: 6rem;
  height: auto;
  max-height: 6rem;
  object-fit: contain;
}

.recent-box-heading {
  font-size: 0.6rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.recent-box-content {
  font-size: 0.6rem;
  text-align: justify;
}
