.privacy-policy-container {
  margin: 4rem;
}

.privacy-policy-heading {
  font-size: 3.5rem;
  font-weight: bold;
  text-align: center;
  margin: 1rem;
}

.privacy-policy-content-heading {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 2rem 0rem 0.5rem;
}

@media only screen and (max-width: 768px) {
  .privacy-policy-container {
    margin: 1rem;
  }

  .privacy-policy-heading {
    font-size: 2.5rem;
  }
}
