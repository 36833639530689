.recommendations-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

.recommendations-heading {
  /* font-family: "Rugen"; */
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  margin: 2rem;
}

.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem;
}

.search {
  width: 35vw;
  border-radius: 1.875rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  margin: 0rem 2rem;
  box-shadow: 0 0.5rem 0.625rem 0.5rem rgba(0, 0, 0, 0.05);
}

.search-img {
  position: relative;
  right: 4.5rem;
}

.filter {
  width: 15vw;
  border-radius: 1.875rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  text-align: left;
  color: #868585;
  margin: 0rem 2rem;
  background-color: white;
  box-shadow: 0 0.5rem 0.625rem 0.5rem rgba(0, 0, 0, 0.05);
}

.filter-img {
  position: relative;
  right: 4.5rem;
}

.filter-dropdown {
  display: none;
  position: absolute;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #9886be;
  opacity: 0.75;
  border: 1px solid #9b91ba;
  color: white;
  z-index: 10;
  width: 15vw;
  padding: 1rem 0rem;
  border-radius: 0.625rem;
  margin-top: 1rem;
  gap: 0.5rem;
  transform: translate(-1rem, 0rem);
}

/* .filter-dropdown a:hover {
  background-color: #9b91ba;
} */

.recommendations-container {
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 2rem;
}

.rcard-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .recommendations-heading {
    font-size: 2rem;
  }

  .search-container {
    margin: 1rem;
  }

  .search {
    width: 60vw;
    margin: 0rem 1rem;
  }

  .search-img,
  .filter-img {
    right: 3.5rem;
  }

  .filter {
    width: 25vw;
    margin: 0rem 1rem;
  }

  .filter-dropdown {
    width: 25vw;
  }

  .recommendations-container {
    grid-template-columns: 1fr 1fr;
    margin: 1rem;
  }
}
