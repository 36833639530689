.main-container {
  background-image: url("/src/assets/images/landing_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 115vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  width: 50vw;
}

.socials {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
}

.icon {
  margin: 1rem;
  filter: drop-shadow(0px 8px 6px rgba(0, 0, 0, 0.25));
}

.grad {
  position: relative;
  height: 12rem;
  background-image: linear-gradient(#ada2ff, #e3dffd);
  z-index: 1;
}

@media (max-width: 768px) {
  .main-container {
    height: 100vh;
  }

  .title {
    width: 75vw;
  }
}
